<template>
  <el-row>
    <el-col :lg="{span: 10, offset: 7}">
      <el-card v-loading.body="isLoading">
        <template #header>
          <h2 class="heading page-heading">
            {{ $t('page_unit_create.title') }}
          </h2>
        </template>
        <el-form
            label-position="top"
            :rules="formRules"
            size="medium"
            status-icon
            :model="form"
            ref="form"
        >
          <el-row>
              <el-col>
                <el-form-item>
                  <el-radio-group v-model="form.unitType">
                    <el-radio :label="$options.unitTypes.UNIT_TYPE_INSTITUTION">
                      {{ $t('page_unit_create.fields.unit_type_institution') }}
                    </el-radio>
                    <el-radio :label="$options.unitTypes.UNIT_TYPE_EMERGENCY">
                      {{ $t('page_unit_create.fields.unit_type_emergency') }}
                    </el-radio>
                    <el-radio :label="$options.unitTypes.UNIT_TYPE_FAMILY">
                      {{ $t('page_unit_create.fields.unit_type_family') }}
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
          </el-row>
          <el-row>
              <el-col>
                <el-form-item
                    prop="title"
                    :label="$t('page_unit_create.fields.unit_name')"
                >
                  <el-input v-model="form.title"/>
                </el-form-item>
              </el-col>
          </el-row>
          <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item
                    prop="banner"
                    :label="$t('page_unit_create.fields.background_image')"
                >
                  <v-file-input
                      :max-size="$options.BANNER_SIZE"
                      v-model="form.banner"
                      :placeholder="
                        $t(
                            'page_unit_create.fields.background_image_placeholder',
                             {size: $options.BANNER_SIZE}
                       )
                      "
                      :acceptable-formats="['image/png', 'image/jpeg']"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                    prop="photo"
                    :label="$t('page_unit_create.fields.image_thumbnail')"
                >
                  <v-file-input
                      :max-size="$options.PHOTO_SIZE"
                      :placeholder="
                        $t(
                            'page_unit_create.fields.background_image_placeholder',
                             {size: $options.PHOTO_SIZE}
                       )
                      "
                      v-model="form.photo"
                      :acceptable-formats="['image/png', 'image/jpeg']"
                  />
                </el-form-item>
              </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item
                  prop="description"
                  :label="$t('page_unit_create.fields.unit_description')"
              >
                <el-input
                    v-model="form.description"
                    type="textarea"
                    :maxlength="200"
                    :label="$t('page_unit_create.fields.unit_description')"
                />
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item prop="unitChildren" :label="$t('page_unit_create.fields.unit_children_label')">
                <el-select :disabled="!isEmergency"
                           :placeholder="$t('page_unit_create.fields.unit_children')"
                           v-model="form.unitChildren" multiple
                >
                  <el-option v-for="child in availableUnitChildren"
                             :key="child.unitId"
                             :value="child.unitId"
                             :label="child.unitTitle"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col>
                <el-button type="primary" @click="handleCreateUnit">
                  {{ $t('page_unit_create.btn_create') }}
                </el-button>
                <el-button @click="$router.push({name: $options.ADMIN_UNIT_LIST_ROUTE_NAME})">
                  {{ $t('page_unit_create.btn_cancel') }}
                </el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import VFileInput from '../../../components/ui/v-file-input/VFileInput'
import { mapState } from 'vuex'
import { createUnit, fetchAvailableUnitChildren } from '../shared/services/unit-service'
import {UNIT_TYPE_EMERGENCY, UNIT_TYPE_INSTITUTION, UNIT_TYPE_FAMILY} from '../shared/constants/units-constants'
import {VALIDATION_ERROR_STATUS_CODE} from '../../../shared/constants/backend'
import {
  UNIT_ABOUT_ROUTE_NAME,
  ADMIN_UNIT_LIST_ROUTE_NAME
} from '../../../shared/constants/app-routes-names'

export default {
  name: 'UnitCreate',
  ADMIN_UNIT_LIST_ROUTE_NAME,
  components: {
    VFileInput
  },
  unitTypes: {
    UNIT_TYPE_INSTITUTION,
    UNIT_TYPE_EMERGENCY,
    UNIT_TYPE_FAMILY
  },
  PHOTO_SIZE: 3,
  BANNER_SIZE: 3,
  data() {
    return {
      availableUnitChildren: [],
      form: {
        unitType: '',
        title: '',
        banner: null,
        photo: null,
        description: '',
        unitChildren: []
      },
      formRules: {
        title: [
          {required: true, message: this.$t('validators.field_required'),}
        ],
        photo: [
          {required: true, message: this.$t('validators.field_required'),},
        ],
        banner: [
          {required: true, message: this.$t('validators.field_required'),},
        ],
        description: [
          {required: true, message: this.$t('validators.field_required'),}
        ],
      }
    }
  },
  created() {
    this.form.unitType = UNIT_TYPE_INSTITUTION
  },
  watch: {
    isEmergency() {
      this.form.unitChildren = []
    }
  },
  mounted() {
    this.fetchAvailableChildren()
  },
  computed: {
    ...mapState('loadingState', {
      isLoading: state => state.isLoading
    }),
    isEmergency() {
      return this.form.unitType === UNIT_TYPE_EMERGENCY
    }
  },
  methods: {
    fetchAvailableChildren() {
      fetchAvailableUnitChildren()
          .then((res) => {
            this.availableUnitChildren = res.data
          })
          .catch(() => {
            this.$message('page_unit_create.load_unit_children_error')
          })
    },
    handleCreateUnit() {
      this.$refs.form.validate((valid) => {
        if (!valid) return
        createUnit(this.form)
            .then(({unitId}) => {
          this.$router.push({name: UNIT_ABOUT_ROUTE_NAME, params: {unitID: unitId}})
        })
        .catch((error) => {
          const errors = []
          if (error.status === VALIDATION_ERROR_STATUS_CODE) {
            error.data.violations.forEach(({propertyPath}) => {
              errors.push(propertyPath.slice(0, propertyPath.indexOf('.')))
            })
          }
          const translateErrors = errors.map(e => {
            return this.$t(`page_unit_create.validation_errors.${e}`)
          })
          this.$message({
            showClose: true,
            message: translateErrors.join(', '),
            type: 'error'
          })
        })
      })
    }
  }
}
</script>
